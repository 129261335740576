<template>
  <div v-if="item">
    <v-row>
      <v-col cols="12" sm="6">
        <vx-card-list title="Basic info" :item="item">
          <v-list-item>
            <v-list-item-title> Id </v-list-item-title>
            <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
              {{ item._id }}
            </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Name </v-list-item-title>
            <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title> Slug </v-list-item-title>
            <v-list-item-subtitle>{{ item.slug }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Page Schema </v-list-item-title>
            <v-list-item-subtitle>{{ item.schemaId?.name }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-list-item-title> Page </v-list-item-title>
            <v-list-item-subtitle>{{ item.pageId?.name }} </v-list-item-subtitle>
          </v-list-item>
          <v-list-item v-if="item.variants?.length">
            <v-list-item-title> Variants </v-list-item-title>
            <v-list-item-subtitle>
              <div v-for="(variant, index) in item.variants" :key="index">{{ variant.name }}</div>
            </v-list-item-subtitle>
          </v-list-item>
        </vx-card-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ABTestCase } from '@tutti/models';
import { ABTestCaseService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  async created() {
    await this.getTestCase();
  },
  methods: {
    async getTestCase() {
      if (this.$route.params.id) {
        const response = await ABTestCaseService.getByIdAdmin(this.$route.params.id, { populateValues: true });
        if (response) {
          this.item = new ABTestCase(response.data);
        }
      }
    },
  },
};
</script>
