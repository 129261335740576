var render = function () {
  var _vm$item$schemaId, _vm$item$pageId, _vm$item$variants;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Slug ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.slug) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Page Schema ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_vm$item$schemaId = _vm.item.schemaId) === null || _vm$item$schemaId === void 0 ? void 0 : _vm$item$schemaId.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Page ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_vm$item$pageId = _vm.item.pageId) === null || _vm$item$pageId === void 0 ? void 0 : _vm$item$pageId.name) + " ")])], 1), (_vm$item$variants = _vm.item.variants) !== null && _vm$item$variants !== void 0 && _vm$item$variants.length ? _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Variants ")]), _c('v-list-item-subtitle', _vm._l(_vm.item.variants, function (variant, index) {
    return _c('div', {
      key: index
    }, [_vm._v(_vm._s(variant.name))]);
  }), 0)], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }